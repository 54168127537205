export enum ConversationParticipationType {
    /**
     * The main agent of the conversation. There can only be one per conversation at any given point in time.
     */
    ASSIGNED_AGENT ='ASSIGNED_AGENT',
    /**
     * The main visitor of the conversation. There can only be one per conversation at any given point in time.
     */
    CONTEXT_PERSON = 'CONTEXT_PERSON',
    /**
     * A secondary agent. Multiple instances per conversation possible.
     */
    SECONDARY_AGENT = 'SECONDARY_AGENT',
    /**
     * A secondary visitor. Multiple instances per conversation possible.
     */
    SECONDARY_VISITOR = 'SECONDARY_VISITOR'
}